import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpBackend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

i18n
  .use(initReactI18next) // Passes i18n instance to react-i18next.
  .use(HttpBackend) // Uses http backend to load translations.
  .use(LanguageDetector) // Detects user language.
  .init({
    fallbackLng: 'en', // Use English as the fallback language.
    debug: false, // Set to true to see logs for debugging.
    ns: [
      'MyUserTasksPage',
      'MyUserTaskPage',
      'MyUserTaskForm',
      'MyUserTaskInfoBox',
      'SecondUserTaskInfoBox',
      'CommonTranslations',
      'AboutPage',
      'HomeLoggedIn',
      'NavigationBar',
      'MyGoalsPage',
      'MyGoalPage',
      'AddTaskToGoalPage',
      'EditGoalPage',
      'EditTaskPage',
      'UserTaskResultGraphs',
      'GoalResultMeasurableGraphs',
      'GoalResultModal',
      'AddGoalPage',
      'TaskStatusDropDown',
      'EventInfoBox',
      'EventComparisonChart',
      'GoalAndTasksInfoBox',
      'AddTaskPage',
      'SignOutComponent',
      'LoginComponent',
      'SignUpComponent',
      'SignUpConfirmation',
      'ResetPasswordComponent',
      'PasswordReset',
      'MyGoalsInfo',
      'MyGoalInfo',
      'AddTaskInfo',
      'InformationModal',
      'MyUserTaskInfo',
      'GoalAddTaskInfo',
      'AddGoalInfo',
      'AddQuestionAndAnswerPage',
      'AddQuestionsAndAnswersInfo',
      'AddTaskToGoalBox',
      'ReviewAndSaveTaskPage',
      'UserTaskStateSaveButtons',
      'GoalResultFinishTasksGraph',
      'ShowAllQAndAPage',
      'FlashCardTaskPage',
      'TaskEventLogModal',
      'TaskEventLogTable',
      'AddEventToCalendar',
      'DropdownSearchComponent',
      'FlipCard',
      'MyAccountPage',
      'MyAccountInfoBox',
      'WrittenAnswerInput',
      'WrittenAnswerResultsView',
      'AddQuestionAndMultipleAnswersPage',
      'MultipleAnswersMainPage',
      'MultipleAnswersInput',
      'MultipleAnswersResultsView',
      'PublicOrganizationTasksPage',
      'SharedOrPublicTaskForm',
      'PublicTasksInfo',
      'ToolsInfo',
      'ToolPage',
      'AiTestGeneratorPage',
    ], // Define namespaces used for different routes.
    defaultNS: 'AboutPage', // Set default namespace used if no other namespace is specified.
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json', // Path to the translation files.
    },
    interpolation: {
      escapeValue: false, // React already safes from XSS.
    },
    react: {
      useSuspense: true, // Configure suspense or not.
    },
  })

export default i18n
