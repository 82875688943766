import { getEnvApiUrl } from '../envConfig'
import {
  MultipleChoiceData,
  QuestionsAndAnswersData,
} from '../pages/admin/ai-generators/AiTestGeneratorPage'
import { AuthService } from './AuthService'

export enum FormType {
  QuestionsAndAnswers = 'QuestionsAndAnswers',
  MultipleChoice = 'MultipleChoice',
}

type OpenAiRequestQuery = {
  formType: FormType
  formData: {
    question: string
  }
}

export type OpenAiResponse = {
  data: QuestionsAndAnswersData | MultipleChoiceData
}

const goachyApiUrl = getEnvApiUrl()

export class OpenAiService {
  private authService: AuthService

  constructor(authService: AuthService) {
    this.authService = authService
  }

  async queryOpenAi(query: OpenAiRequestQuery): Promise<OpenAiResponse> {
    try {
      const jwtToken = await this.authService.getJwtToken()

      if (!jwtToken) {
        throw new Error('Missing jwtToken')
      }

      const openAiResponse = await fetch(`${goachyApiUrl}ai/query`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwtToken,
        },
        body: JSON.stringify(query),
      })

      if (!openAiResponse.ok) {
        throw new Error('Failed to get open ai response')
      }

      let data
      switch (query.formType) {
        case FormType.QuestionsAndAnswers: {
          data = (await openAiResponse.json()) as QuestionsAndAnswersData
          break
        }
        case FormType.MultipleChoice: {
          data = (await openAiResponse.json()) as MultipleChoiceData
          break
        }
        default:
          throw Error(`Not a valid form type: ${query.formType}`)
      }

      return {
        data: data,
      }
    } catch (error) {
      console.error('Failed to get open ai response', error)
      throw error
    }
  }
}
