import {
  faCircleArrowDown,
  faCircleArrowUp,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useRef, useState } from 'react'
import { Button, Card, Col, Container, ProgressBar, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import StyledContainer from '../../../components/StyledContainer'
import TextToSpeechComponent from '../../../components/TextToSpeechComponent'
import { MultipleAnswersData } from '../../../components/model/model'

import { useNavigate } from 'react-router-dom'
import { useGoal } from '../../../context/GoalContext'
import { useUserCredentials } from '../../../context/UserCredentialsContext'
import { shuffleArray } from '../../../utils/helperFunctions'
import './MultipleAnswersInput.css'
import MultipleAnswersResultsView from './MultipleAnswersResultsView'

export type MultipleAnswersItem = {
  id: number
  question: string
  answer: string
  correctAnswer: string
  points: 0 | 1
}

export type MultipleAnswerResult = {
  multipleAnswersItems: Map<number, MultipleAnswersItem>
  totalPoints: number
}

export interface MultipleAnswersInputProps {
  item: MultipleAnswersData
  currentIndex: number
  lastIndexOfItems: number
  withPoints?: boolean
  increaseCurrentItemIndex: () => void
  playAgain: (allQuestions: boolean, indexes?: number[]) => void
  saveResultInTaskEventCb: (
    withPoints: boolean,
    result: MultipleAnswerResult,
  ) => void
}

const MultipleAnswersInput = ({
  item,
  currentIndex,
  lastIndexOfItems,
  increaseCurrentItemIndex,
  withPoints = false,
  playAgain,
  saveResultInTaskEventCb,
}: MultipleAnswersInputProps) => {
  const { t } = useTranslation('MultipleAnswersInput')
  const inputRef = useRef<HTMLInputElement>(null)
  const [showTextToSpeech, setShowTextToSpeech] = useState<boolean>(false)
  const [answerInput, setAnswerInput] = useState('')
  const [correctAnswer, setCorrectAnswer] = useState('')
  const [doneWithGame, setDoneWithGame] = useState(false)
  const [multipleAnswerResult, setMultipleAnswerResult] =
    useState<MultipleAnswerResult>({} as MultipleAnswerResult)
  const [shuffledAnswers, setShuffledAnswers] = useState<string[]>([])
  const [isPreviousAnswerCorrect, setIsPreviousAnswerCorrect] = useState(false)

  const { goalInContext } = useGoal()
  const { userCredentialsInContext } = useUserCredentials()

  const navigate = useNavigate()

  useEffect(() => {
    const alternatives = [item.answer, item.alternative1, item.alternative2]
    setShuffledAnswers(shuffleArray(alternatives))
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [item])

  const handleSelectedAnswer = async (selectedAnswer: string) => {
    const isAnswerCorrect =
      item.answer.toLowerCase() === selectedAnswer.toLowerCase()

    setAnswerInput(selectedAnswer)
    setCorrectAnswer(item.answer)
    setIsPreviousAnswerCorrect(isAnswerCorrect)
    keepMultipeAnswersResultInState(selectedAnswer)

    if (currentIndex === lastIndexOfItems) {
      saveAndFinishGame()
    } else {
      increaseCurrentItemIndex()
    }
  }

  const saveAndFinishGame = async () => {
    saveResultInTaskEventCb(withPoints, multipleAnswerResult)
    setDoneWithGame(true)
  }

  const handleNextQuestion = () => {
    keepMultipeAnswersResultInState(answerInput)

    if (currentIndex === lastIndexOfItems) {
      saveResultInTaskEventCb(withPoints, multipleAnswerResult)
      setDoneWithGame(true)
    } else {
      increaseCurrentItemIndex()
    }
  }

  const isOnLastItem = () => {
    return currentIndex === lastIndexOfItems
  }

  const isOnFirstItem = () => {
    console.log('currentIndex', currentIndex)
    return currentIndex === 0
  }

  const keepMultipeAnswersResultInState = (currentInputAnswer: string) => {
    const points =
      item.answer.toLowerCase() === currentInputAnswer.toLowerCase() ? 1 : 0

    const multipleAnswersItem: MultipleAnswersItem = {
      id: currentIndex,
      question: item.question,
      answer: currentInputAnswer,
      correctAnswer: item.answer,
      points: points,
    }

    if (!multipleAnswerResult.totalPoints) {
      multipleAnswerResult.totalPoints = 0
    }

    multipleAnswerResult.totalPoints = multipleAnswerResult.totalPoints + points

    if (!multipleAnswerResult.multipleAnswersItems) {
      multipleAnswerResult.multipleAnswersItems = new Map<
        number,
        MultipleAnswersItem
      >()
    }

    multipleAnswerResult.multipleAnswersItems.set(
      currentIndex,
      multipleAnswersItem,
    )

    setMultipleAnswerResult(multipleAnswerResult)
  }

  const saveLastAnswerAndShowResult = () => {
    keepMultipeAnswersResultInState(answerInput)
    setDoneWithGame(true)
    saveResultInTaskEventCb(withPoints, multipleAnswerResult)
  }

  const startOver = (all: boolean, errors: boolean, misspellings: boolean) => {
    if (all) {
      playAgain(true)
    } else if (errors && misspellings) {
      const incorrectAnswerIdx = Array.from(
        multipleAnswerResult.multipleAnswersItems.values(),
      )
        .filter((item) => item.points === 0)
        .map((item) => item.id)

      playAgain(false, incorrectAnswerIdx)
    } else if (errors) {
      const incorrectAnswerIdx = Array.from(
        multipleAnswerResult.multipleAnswersItems.values(),
      )
        .filter((item) => item.points === 0)
        .map((item) => item.id)

      playAgain(false, incorrectAnswerIdx)
    } else if (misspellings) {
      const misspellingAnswerIdx = Array.from(
        multipleAnswerResult.multipleAnswersItems.values(),
      )
        .filter((item) => item.points === 1)
        .map((item) => item.id)

      playAgain(false, misspellingAnswerIdx)
    }
    setDoneWithGame(false)
    saveResultInTaskEventCb(withPoints, multipleAnswerResult)
    setMultipleAnswerResult({} as MultipleAnswerResult)
  }

  const hasIncorrectAnswers = () => {
    if (!withPoints) {
      return false
    }

    return Array.from(multipleAnswerResult.multipleAnswersItems.values()).some(
      (item) => item.points === 0,
    )
  }

  const taskDoneNavigateBack = () => {
    if (goalInContext) {
      navigate(`/goals/open`)
    } else {
      navigate('/usertasks/list')
    }
  }

  const isShortText = (text: string) => {
    return text.length < 20
  }

  const getCurrentProgress = () => {
    if (!multipleAnswerResult.totalPoints) {
      return 0
    }
    return (multipleAnswerResult.totalPoints / (lastIndexOfItems + 1)) * 100
  }

  return (
    <>
      {!doneWithGame && (
        <>
          {/* TEXT TO SPEECH */}
          {userCredentialsInContext && userCredentialsInContext.isSignedIn && (
            <>
              <div className={'text-center mb-3'}>
                <Button
                  onClick={() => setShowTextToSpeech(!showTextToSpeech)}
                  variant="secondary"
                  style={{ borderRadius: 15 }}
                >
                  <FontAwesomeIcon
                    icon={
                      showTextToSpeech ? faCircleArrowUp : faCircleArrowDown
                    }
                    size={'lg'}
                  ></FontAwesomeIcon>{' '}
                  {showTextToSpeech ? t('closeSpeech') : t('showSpeech')}
                </Button>
              </div>

              <div
                className={'mx-2'}
                style={{ display: showTextToSpeech ? 'block' : 'none' }}
              >
                <TextToSpeechComponent
                  textToSpeak={item.question}
                  label={t('question')}
                />

                <TextToSpeechComponent
                  textToSpeak={shuffledAnswers[0]}
                  label={t('alternative1')}
                />
                <TextToSpeechComponent
                  textToSpeak={shuffledAnswers[1]}
                  label={t('alternative2')}
                />
                <TextToSpeechComponent
                  textToSpeak={shuffledAnswers[2]}
                  label={t('alternative3')}
                />
              </div>
            </>
          )}

          {/* POINTS, QUESTION AND ANSWERS */}
          <StyledContainer useTaskCardBg={true}>
            {/* POINTS */}
            <div className="d-flex flex-column mx-3">
              {/* QUESTION */}
              <Container className="text-center" style={{ minHeight: '120px' }}>
                <div
                  className={`g-task-description ${
                    isShortText(item.question) ? 'large-text' : ''
                  }`}
                >
                  {item.question}
                </div>
              </Container>

              {/* Progress Bar */}
              <Container className="my-3">
                <ProgressBar
                  className="custom-progress-bar"
                  now={getCurrentProgress()}
                  label={`${multipleAnswerResult.totalPoints}/${lastIndexOfItems + 1}`}
                />
              </Container>

              {/* ALTERNATIVES */}
              <Container>
                <Row className="g-4 cursorPointer">
                  {shuffledAnswers.map((choice, idx) => (
                    <Col
                      key={idx}
                      sm={4}
                      onClick={() => handleSelectedAnswer(choice)}
                    >
                      <Card style={{ backgroundColor: '#A5D8FF' }}>
                        <Card.Body>
                          <Card.Text>{choice}</Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Container>
            </div>

            {!isOnLastItem() && (
              <div className={'my-3 text-center mx-4'}>
                <Button
                  variant="primary"
                  className={'w-100'}
                  onClick={() => handleNextQuestion()}
                >
                  {t('nextButton')}
                </Button>
              </div>
            )}
            {isOnLastItem() && (
              <div className={'text-center'}>
                <Button
                  variant="success"
                  onClick={() => saveLastAnswerAndShowResult()}
                  className="mt-4"
                >
                  {t('showResultButton')}
                </Button>
              </div>
            )}

            {isOnFirstItem() && (
              <>
                <hr />
                <div style={{ minHeight: '150px' }}></div>
              </>
            )}

            {isPreviousAnswerCorrect && !isOnFirstItem() && (
              <>
                <hr />
                <Card className="result-card" style={{ minHeight: '150px' }}>
                  <Card.Body>
                    <p
                      style={{
                        color: '#228B22',
                      }}
                    >
                      <strong>{t('yourAnswer')}</strong>{' '}
                      <span style={{ marginLeft: '0.5em' }}>{answerInput}</span>
                    </p>
                  </Card.Body>
                </Card>
              </>
            )}

            {!isPreviousAnswerCorrect && !isOnFirstItem() && (
              <>
                <hr />
                <Card className="result-card">
                  <Card.Body>
                    <p
                      style={{
                        color: 'red',
                      }}
                    >
                      <strong>{t('yourAnswer')}</strong>{' '}
                      <span style={{ marginLeft: '0.5em' }}>{answerInput}</span>
                    </p>
                    <p
                      style={{
                        color: '#228B22',
                      }}
                    >
                      <strong>{t('correctAnswer')}</strong>{' '}
                      <span style={{ marginLeft: '0.5em' }}>
                        {correctAnswer}
                      </span>
                    </p>
                  </Card.Body>
                </Card>
              </>
            )}
            <div className={'mt-2'}></div>
            <hr />
          </StyledContainer>

          {!doneWithGame && (
            <Container className="my-4">
              <Row className="justify-content-center">
                <Col xs="auto">
                  <p>
                    {currentIndex + 1} {t('of')} {lastIndexOfItems + 1}
                  </p>
                </Col>
              </Row>
            </Container>
          )}

          <div className={'my-3'}></div>
        </>
      )}

      {/* RESULT SECTION */}
      {doneWithGame && (
        <>
          <MultipleAnswersResultsView
            withPoints={withPoints ? withPoints : false}
            writtenAnswerResult={multipleAnswerResult}
          />
          <div className={'text-center'}>
            <Button
              variant="primary"
              onClick={() => startOver(true, false, false)}
              className="mt-4 w-100"
            >
              {t('playAgainAllQuestionsButton')}
            </Button>
          </div>

          {hasIncorrectAnswers() && (
            <div className={'text-center'}>
              <Button
                variant="primary"
                onClick={() => startOver(false, true, false)}
                className="mt-4 w-100"
              >
                {t('playAgainAllIncorrectAnswersButton')}
              </Button>
            </div>
          )}

          <hr />

          {userCredentialsInContext && userCredentialsInContext.isSignedIn && (
            <div className={'text-center'}>
              <Button
                variant="secondary"
                onClick={() => taskDoneNavigateBack()}
                className="mt-4 w-100"
              >
                {t('doneButton')}
              </Button>
            </div>
          )}
        </>
      )}
    </>
  )
}

export default MultipleAnswersInput
