import { Col, Container, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router-dom'
import { useUserCredentials } from '../context/UserCredentialsContext'
import { hasRoleOrganizationRoleOrHigher } from '../utils/UserDetailsHelper'
import GoachyInfoItem from './GoachyInfoItem'

export const HomeLoggedIn = () => {
  const { t } = useTranslation('HomeLoggedIn')
  const { userCredentialsInContext } = useUserCredentials()
  const navigate = useNavigate()

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  if (
    !userCredentialsInContext ||
    userCredentialsInContext?.isSignedIn === false
  ) {
    navigate('/login')
  }

  const getHeaderTitleClass = () => {
    return isMobile ? 'goachy-header-title-mobile' : 'goachy-header-title'
  }

  const organizationUserPath = `/admin/organizations/${userCredentialsInContext?.userInfo?.organizationId}/users`

  return (
    <div>
      <div className="display-6 text-center my-4"></div>

      <div className={`${getHeaderTitleClass()} text-center my-4`}>
        {t('goalsAndTasksHeader')}
      </div>

      <Container>
        <Row className="justify-content-md-center">
          <Col md={10}>
            <GoachyInfoItem
              title={t('myGoals')}
              text={t('manageMyGoals')}
              link="goals/my"
            />

            <GoachyInfoItem
              title={t('myTasks')}
              text={t('manageMyTasks')}
              link="/usertasks/list"
            />

            {/* {hasRoleToCreateGoals(userCredentialsInContext!) && (
              <GoachyInfoItem
                title={t('createGoal')}
                text={t('createNewGoals')}
                link="/goals/add"
              />
            )}

            {hasRoleToCreateTasks(userCredentialsInContext!) && (
              <GoachyInfoItem
                title={t('createTasks')}
                text={t('createNewTasks')}
                link="/admin/tasks/add"
              />
            )} */}

            <GoachyInfoItem
              title={t('showAiTestGenerator')}
              text={t('manageAiTestGenerator')}
              link="/ai/generators/test"
            />

            {hasRoleOrganizationRoleOrHigher(userCredentialsInContext!) && (
              <GoachyInfoItem
                title={t('showAllTasks')}
                text={t('manageAllTasksInOrg')}
                link="/admin/tasks/list"
              />
            )}

            {hasRoleOrganizationRoleOrHigher(userCredentialsInContext!) && (
              <GoachyInfoItem
                title={t('showAllGoals')}
                text={t('manageAllGoalsInOrg')}
                link="/goals/list"
              />
            )}

            {hasRoleOrganizationRoleOrHigher(userCredentialsInContext!) && (
              <GoachyInfoItem
                title={t('showAllUsers')}
                text={t('manageAllUsersInOrg')}
                link={organizationUserPath}
              />
            )}
          </Col>
        </Row>
      </Container>
    </div>
  )
}
export default HomeLoggedIn
