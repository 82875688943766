import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormEvent, useEffect, useState } from 'react'
import { Button, Card, Col, Container, Form, Row, Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import LeftButtonTitleRightButton from '../../../components/LeftButtonTitleRightButton'
import {
  Language,
  TaskDataType,
  TaskStatus,
} from '../../../components/model/model'
import ProgressSpinner from '../../../components/ProgressSpinner'
import StyledContainer from '../../../components/StyledContainer'
import { useUserCredentials } from '../../../context/UserCredentialsContext'
import { FormType } from '../../../service/OpenAiService'
import {
  useOpenAiService,
  useTaskService,
} from '../../../service/ServicesHooks'
import { PostTaskRequest } from '../../../service/TaskService'
import { getCurrentLanguage } from '../../../utils/UserDetailsHelper'
import { useUserTasksContext } from '../../../context/UserTaskListContext'

export type QuestionsAndAnswersData = { question: string; answer: string }[]
export type MultipleChoiceData = {
  question: string
  answer: string
  alternative1: string
  alternative2: string
}[]

const AiTestGeneratorPage = () => {
  const { t } = useTranslation('AiTestGeneratorPage')
  const navigate = useNavigate()
  const openAiService = useOpenAiService()
  const taskService = useTaskService()
  const { userCredentialsInContext } = useUserCredentials()
  const { clearUserTasks } = useUserTasksContext()
  const [name, setName] = useState<string>('')
  const [difficulty, setDifficulty] = useState<string>('medium')
  const [topic, setTopic] = useState<string>('')
  const [numberOfQuestions, setNumberOfQuestions] = useState<number>(10)
  const [words, setWords] = useState<string>('')
  const [category, setCategory] = useState<string>('')
  const [language, setLanguage] = useState<Language>(Language.English)
  const [validatedTestGeneration, setValidatedTestGeneration] =
    useState<boolean>(false)
  const [validatedSaveTest, setValidatedSaveTest] = useState<boolean>(false)
  const [showProgressSpinner, setShowProgressSpinner] = useState<boolean>(false)

  const [questionsAndAnswersResult, setQuestionsAndAnswersResult] =
    useState<QuestionsAndAnswersData>({} as QuestionsAndAnswersData)

  const [multipleChoiceResult, setMultipleChoiceResult] =
    useState<MultipleChoiceData>({} as MultipleChoiceData)

  const [showResult, setShowResult] = useState<boolean>(false)
  const [selectedTestFormType, setSelectedTestFormType] = useState<FormType>(
    FormType.QuestionsAndAnswers,
  )

  useEffect(() => {
    //Initially set language to the current user language
    setLanguage(getCurrentLanguage())
  }, [])

  // CREATE A QUERY

  // Format for the question and answer query
  const questionsAndAnswersFormat = [
    { question: 'question1', answer: 'answer1' },
    { question: 'question2', answer: 'answer2' },
  ]

  //Format for the multiple choice question
  const multipleChoiceFormat = [
    {
      question: 'What is the capital city of Germany?',
      answer: 'Berlin',
      alternative1: 'Munich',
      alternative2: 'Hamburg',
    },
    {
      question: 'What is the capital city of Brazil?',
      answer: 'Brasília',
      alternative1: 'Rio de Janeiro',
      alternative2: 'São Paulo',
    },
  ]

  const questionsAndAnswersFormatExplanation = `I need the data to be returned in an exact JSON format, where each item is an object with two fields: 'question' and 'answer'. The format should look like this: ${JSON.stringify(questionsAndAnswersFormat)}`
  const multipleChoiceFormatExplanation = `I need the data to be returned in an exact JSON format, where each item is an object with three fields: 'question', 'answer', 'alternative1' and 'alternative2'. The format should look like this: ${JSON.stringify(multipleChoiceFormat)}`

  const buildQuestion = () => {
    let formatExplanation = ''
    if (selectedTestFormType === FormType.QuestionsAndAnswers) {
      formatExplanation = questionsAndAnswersFormatExplanation
    } else {
      formatExplanation = multipleChoiceFormatExplanation
    }

    return `Skapa ett test baserat på:\n-Ämne: ${topic}\n
    -Nyckelord som ska användas för att skapa testet: ${words}\n
    -Antal frågor: ${numberOfQuestions}\n
    -Svårighetsgraden: ${difficulty}, där det finns att välja mellan (lätt, ganska lätt, medel, ganska svår, svår)\n
    -Svara på språket: ${language}\n
    -${formatExplanation}`
  }
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.stopPropagation()
    } else {
      setShowProgressSpinner(true)

      const response = await openAiService.queryOpenAi({
        formType: selectedTestFormType,
        formData: {
          question: buildQuestion(),
        },
      })

      console.log('Result:', response.data)

      switch (selectedTestFormType) {
        case FormType.QuestionsAndAnswers:
          setQuestionsAndAnswersResult(response.data)
          break
        case FormType.MultipleChoice:
          setMultipleChoiceResult(response.data as MultipleChoiceData)
          break
      }
    }
    setShowProgressSpinner(false)
    setValidatedTestGeneration(true)
  }

  const handleRemoveQuestionAndAnswerItem = (index: number) => {
    setQuestionsAndAnswersResult((prevResult) =>
      prevResult.filter((_, i) => i !== index),
    )
  }

  const handleMultipleChoiceItem = (index: number) => {
    setQuestionsAndAnswersResult((prevResult) =>
      prevResult.filter((_, i) => i !== index),
    )
  }

  const handleSaveTest = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.stopPropagation()
    } else {
      setShowProgressSpinner(true)

      const data =
        selectedTestFormType === FormType.QuestionsAndAnswers
          ? questionsAndAnswersResult
          : multipleChoiceResult

      const dataType =
        selectedTestFormType === FormType.QuestionsAndAnswers
          ? TaskDataType.QuestionAndAnswerDataType
          : TaskDataType.MultipleChoiceDataType

      const createTaskRequest: PostTaskRequest = {
        task: {
          id: '',
          name: name,
          category: category,
          data: JSON.stringify(data),
          dataType: dataType,
          userId: userCredentialsInContext!.userInfo!.userId,
          organizationId: userCredentialsInContext!.userInfo!.organizationId,
          status: TaskStatus.Private,
          language: language,
        },
        email: userCredentialsInContext!.userInfo!.email,
      }
      await taskService.postTask(createTaskRequest)
    }
    //TODO: Just add the new task to the list of tasks, instead of clearing the list and refetching all tasks, see fetchUserTaskAndUpdateUserTasksContext in AddQuestionAndAnswerPage
    clearUserTasks() // Clear user tasks, so they will be refetched.
    setValidatedSaveTest(true)
    setShowProgressSpinner(false)
  }

  const handleNumberOfQuestionsChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setNumberOfQuestions(Number(e.target.value))
  }

  const handleSelectTestType = (eventKey: string | null) => {
    if (eventKey) {
      setSelectedTestFormType(eventKey as FormType)
    }
  }

  if (showProgressSpinner) {
    return <ProgressSpinner />
  }

  return (
    <>
      <div className={'mt-2'}></div>
      <StyledContainer useTaskCardBg={true}>
        <LeftButtonTitleRightButton
          title={t('quizGeneratorPageTitle')}
          leftButtonTitle={t('backButton')}
          showRightButton={false}
          onLeftButtonClick={() => navigate(-1)}
        />

        <hr />

        <Container>
          <Row className="justify-content-md-center">
            <Col md={12}>
              <Card className="mt-4">
                <Card.Body>
                  <h3 className="text-center">{t('createTestHeader')}</h3>

                  <Form
                    noValidate
                    validated={validatedTestGeneration}
                    onSubmit={handleSubmit}
                  >
                    {/* TYPE OF TEST */}
                    <Form.Group controlId="formTestType" className="mt-4">
                      <Form.Label className="text-mid-size">
                        {t('testTypeLabel')}
                      </Form.Label>
                      <Form.Control
                        required
                        as="select"
                        value={selectedTestFormType}
                        onChange={(e) => handleSelectTestType(e.target.value)}
                      >
                        <option value={FormType.QuestionsAndAnswers}>
                          {t('testTypeOptions.questionsAndAnswers')}
                        </option>
                        <option value={FormType.MultipleChoice}>
                          {t('testTypeOptions.multipleChoice')}
                        </option>
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {t('formTestTypeFeedback')}
                      </Form.Control.Feedback>
                    </Form.Group>

                    {/* TOPIC */}
                    <Form.Group className="mt-4">
                      <Form.Label className="text-mid-size">
                        {t('quizTopicLabel')}
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        placeholder={t('quizTopicPlaceholder')}
                        value={topic}
                        onChange={(e) => setTopic(e.target.value)}
                      />
                      <Form.Text className="text-muted">
                        {t('topicDescription')}
                      </Form.Text>
                      <Form.Control.Feedback type="invalid">
                        {t('topicFeedback')}
                      </Form.Control.Feedback>
                    </Form.Group>

                    {/* KEYWORDS */}
                    <Form.Group controlId="formWords" className="mt-4">
                      <Form.Label className="text-mid-size">
                        {t('wordsLabel')}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t('wordsPlaceholder')}
                        value={words}
                        onChange={(e) => setWords(e.target.value)}
                      />
                      <Form.Text className="text-muted">
                        {t('wordsDescription')}
                      </Form.Text>
                    </Form.Group>

                    {/* DIFFICULTY */}
                    <Form.Group controlId="formDifficulty" className="mt-4">
                      <Form.Label className="text-mid-size">
                        {t('difficultyLabel')}
                      </Form.Label>
                      <Form.Control
                        required
                        as="select"
                        value={difficulty}
                        onChange={(e) => setDifficulty(e.target.value)}
                      >
                        <option value="">{t('difficultyPlaceholder')}</option>
                        <option value="easy">
                          {t('difficultyOptions.easy')}
                        </option>
                        <option value="fairlyEasy">
                          {t('difficultyOptions.fairlyEasy')}
                        </option>
                        <option value="medium">
                          {t('difficultyOptions.medium')}
                        </option>
                        <option value="fairlyHard">
                          {t('difficultyOptions.fairlyHard')}
                        </option>
                        <option value="difficult">
                          {t('difficultyOptions.difficult')}
                        </option>
                      </Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {t('difficultyFeedback')}
                      </Form.Control.Feedback>
                    </Form.Group>

                    {/* NUMBER OF QUESTIONS */}
                    <Form.Group
                      controlId="formNumberOfQuestions"
                      className="mt-4"
                    >
                      <Form.Label className="text-mid-size">
                        {t('numberOfQuestionsLabel')}
                      </Form.Label>
                      <Form.Control
                        type="range"
                        min={1}
                        max={20}
                        value={numberOfQuestions}
                        onChange={handleNumberOfQuestionsChange}
                      />
                      <Form.Text className="text-muted">
                        {numberOfQuestions}
                      </Form.Text>
                    </Form.Group>

                    {/* LANGUAGE */}
                    <Form.Group controlId="formLanguage" className="mt-4">
                      <Form.Label className="text-mid-size">
                        {t('languageLabel')}
                      </Form.Label>
                      <Form.Control
                        as="select"
                        value={language}
                        onChange={(e) =>
                          setLanguage(e.target.value as Language)
                        }
                      >
                        <option value={Language.Swedish}>
                          {t('languageOptions.swedish')}
                        </option>
                        <option value={Language.English}>
                          {t('languageOptions.english')}
                        </option>
                        <option value={Language.Spanish}>
                          {t('languageOptions.spanish')}
                        </option>
                        <option value={Language.German}>
                          {t('languageOptions.german')}
                        </option>
                        <option value={Language.French}>
                          {t('languageOptions.french')}
                        </option>
                      </Form.Control>
                    </Form.Group>

                    {/* GENERATE TEST BUTTON */}
                    <div className="d-flex justify-content-center mt-4">
                      <Button
                        variant="primary"
                        type="submit"
                        className="btn-lg"
                      >
                        {t('generateButton')}
                      </Button>
                    </div>
                  </Form>
                </Card.Body>
              </Card>

              {/* GENERATED RESULT */}
              {(questionsAndAnswersResult.length > 0 ||
                multipleChoiceResult.length > 0) && (
                <Card className="mt-4">
                  <Card.Body>
                    <h3 className="text-center">{t('testResultHeader')}</h3>
                    <div className="text-center">
                      <p>
                        {t('summaryTopic')}: {topic}
                      </p>
                      <p>
                        {t('summaryDifficulty')}: {difficulty}
                      </p>
                      <p>
                        {t('summaryNumberOfQuestions')}: {numberOfQuestions}
                      </p>
                      <p>
                        {t('summaryLanguage')}: {language}
                      </p>
                    </div>

                    <div className="d-flex justify-content-center mt-4">
                      <Button
                        variant="primary"
                        className="btn-lg"
                        onClick={() => setShowResult(!showResult)}
                      >
                        {showResult
                          ? t('hideResultButton')
                          : t('showResultButton')}
                      </Button>
                    </div>

                    {/* SHOW RESULT FOR QUESTIONS AND ANSWERS */}
                    {showResult &&
                      selectedTestFormType === FormType.QuestionsAndAnswers && (
                        <Table striped bordered hover className="mt-4">
                          <thead>
                            <tr>
                              <th>{t('questionHeader')}</th>
                              <th>{t('answerHeader')}</th>
                              <th>{t('actionsHeader')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {questionsAndAnswersResult.map((item, index) => (
                              <tr key={index}>
                                <td>{item.question}</td>
                                <td>{item.answer}</td>
                                <td>
                                  <FontAwesomeIcon
                                    className="cursorPointer"
                                    onClick={() =>
                                      handleRemoveQuestionAndAnswerItem(index)
                                    }
                                    icon={faTrash}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      )}

                    {/* SHOW RESULT FOR MULTIPLECHOICE */}
                    {showResult &&
                      selectedTestFormType === FormType.MultipleChoice && (
                        <div className="mt-4">
                          {multipleChoiceResult.map((item, index) => (
                            <Card key={index} className="mb-3">
                              <Card.Body>
                                <Card.Title>
                                  {t('questionHeader')} {index + 1}
                                </Card.Title>
                                <Card.Text>{item.question}</Card.Text>
                                <Card.Title>{t('answerHeader')}</Card.Title>
                                <Card.Text>{item.answer}</Card.Text>
                                <Card.Title>{t('alternative1')}</Card.Title>
                                <Card.Text>{item.alternative1}</Card.Text>
                                <Card.Title>{t('alternative2')}</Card.Title>
                                <Card.Text>{item.alternative2}</Card.Text>
                                <div className="d-flex justify-content-end">
                                  <FontAwesomeIcon
                                    className="cursorPointer"
                                    onClick={() =>
                                      handleMultipleChoiceItem(index)
                                    }
                                    icon={faTrash}
                                  />
                                </div>
                              </Card.Body>
                            </Card>
                          ))}
                        </div>
                      )}
                  </Card.Body>
                </Card>
              )}

              {/* SAVE TEST */}
              {(questionsAndAnswersResult.length > 0 ||
                multipleChoiceResult.length > 0) && (
                <Card className="mt-4">
                  <Card.Body>
                    <h3 className="text-center">{t('saveTestInfo')}</h3>
                    <Form
                      noValidate
                      validated={validatedSaveTest}
                      onSubmit={handleSaveTest}
                    >
                      {/* NAME OF TEST INPUT */}
                      <Form.Group controlId="formName" className="mt-4">
                        <Form.Label className="text-mid-size">
                          {t('nameLabel')}
                        </Form.Label>
                        <Form.Control
                          required
                          type="text"
                          placeholder={t('namePlaceholder')}
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid">
                          {t('nameFeedback')}
                        </Form.Control.Feedback>
                      </Form.Group>

                      {/* CATEGORY OF TEST INPUT */}
                      <Form.Group controlId="formCategory" className="mt-4">
                        <Form.Label className="text-mid-size">
                          {t('categoryLabel')}
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder={t('categoryPlaceholder')}
                          value={category}
                          onChange={(e) => setCategory(e.target.value)}
                        />
                      </Form.Group>

                      {/* SAVE TEST BUTTON */}
                      <div className="d-flex justify-content-center mt-4">
                        <Button
                          variant="primary"
                          type="submit"
                          className="btn-lg"
                        >
                          {t('saveButton')}
                        </Button>
                      </div>
                    </Form>
                  </Card.Body>
                </Card>
              )}
            </Col>
          </Row>
        </Container>
      </StyledContainer>
      <div className={'mb-2'}></div>
    </>
  )
}

export default AiTestGeneratorPage
